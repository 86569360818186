import React from 'react';


function NotFound() {

  
  return (
    <div className="notfound">
        <p>404 NOT FOUND</p>
    </div>
  );
}

export default NotFound;
